import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  box-sizing: inherit;
  max-width: 1000px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 140px;
  padding-bottom: 140px;
  line-height: 38px;
`;

export const Paragraph = styled.p`
  font-family: "Produkt";
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1em;
  text-align: justify;
  text-justify: inter-word;
}
  b {
    font-family: "Produkt";
  }
`;

export const ParagraphTitle = styled.p`
  font-family: "EuropaBold";
`;

export const Wrap = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Produkt";
  text-align: justify;
  text-justify: inter-word;
  b {
    font-family: "Produkt";
  }
`;

export const Title = styled.h1`
  font-size: 2.3em;
  margin-bottom: 60px;
  text-align: center;
`;

import React from "react";
import {
  Container,
  Paragraph,
  ParagraphTitle,
  Wrap,
  Title,
} from "./policy.styles";
const PolicyContent: React.FC = () => {
  return (
    <Container>
      <Title>Política de Privacidade</Title>
      <ParagraphTitle>I. Introdução</ParagraphTitle>
      <Paragraph>
        Sua privacidade e os seus direitos, são os nossos compromissos. Na
        ABBIAMO, nós respeitamos sua privacidade e protegemos seus dados, essa é
        uma prioridade.
        <br />
        Elaboramos este documento com a finalidade de estabelecer e deixar claro
        para os titulares dos dados (você) as regras de coleta, armazenamento,
        utilização, tratamento, compartilhamento e exclusão de dados e
        informações eventualmente coletados por nós, em plataformas digitais,
        sites, eventos e cadastros.
        <br />
        Em outras palavras, esta política tem o intuito de explicar a você sobre
        como trataremos seus dados que você nos fornece ao acessar nossas
        plataformas em geral.
        <br />
        Quando você usa a Abbiamo Log, você nos confia seus dados pessoais. E
        temos o compromisso de manter essa confiança. Para começar, queremos
        ajudar você a compreender nossas práticas de privacidade. Este aviso
        descreve os dados pessoais que coletamos, como eles são usados e
        compartilhados e as suas opções com relação a esses dados.
      </Paragraph>
      <br />
      <ParagraphTitle>II. Visão Geral</ParagraphTitle>
      <Paragraph>
        Este aviso aplica-se aos usuários dos serviços da Abbiamo Log no Brasil,
        incluindo usuários de apps, sites, recursos ou outros serviços da
        Abbiamo Log. Este aviso se aplica especificamente:
        <br />
        <br />
        <Wrap>
          <b>a)</b> Usuários: indivíduos que solicitam ou recebem transporte,
          incluindo os que recebem transporte solicitado por outra pessoa.
          <br />
          <b>b)</b> Entregadores parceiros: indivíduos que oferecem ou se
          inscrevem para oferecer transporte de entregas com a Abbiamo Log
          individualmente ou por meio de empresas parceiras de transporte.
          <br />
          <b>c)</b> Destinatários de entrega: indivíduos que solicitam ou
          recebem os itens objeto da entrega. Este aviso também se aplica a
          outras coletas de dados pessoais pela Abbiamo Log, em conexão com os
          serviços da Abbiamo Log. Por exemplo, podemos coletar informações de
          contato de indivíduos que usem contas de clientes da Abbiamo Log para
          Empresas ou de proprietários ou colaboradores de restaurantes
          parceiros da Abbiamo Log, ou podemos coletar outros dados pessoais em
          conexão com nossa tecnologia e recursos de mapa. Todos os indivíduos
          sujeitos ao presente aviso são denominados {"usuários"} no presente
          presente aviso.
        </Wrap>
      </Paragraph>
      <br />
      <ParagraphTitle>
        III. COLETA, COMPARTILHAMENTO E USO DE DADOS
      </ParagraphTitle>
      <br />
      <Paragraph>
        <b>1.</b> Dados informados pelos usuários. Isso inclui: Perfil do
        usuário: coletamos dados quando os usuários criam ou alteram suas contas
        da Abbiamo Log. Isso pode incluir nome, e-mail, número de telefone,
        login e senha, endereço, foto do perfil, informações de pagamento ou
        dados bancários (inclusive as de confirmação de pagamento relacionadas),
        documentos de identidade oficiais, incluindo número e imagem da carteira
        de habilitação, data de nascimento, assinatura e foto. Esses dados
        também incluem informações dos entregadores e parceiros de entregas e
        configurações do usuário. Podemos usar as fotos enviadas pelos
        entregadores e/ou parceiros de entregas para confirmar suas identidades,
        por exemplo, por meio de tecnologias de reconhecimento facial. Checagem
        de antecedentes e confirmação de identidade: coletamos informações de
        checagem de antecedentes e confirmação de identidade de entregadores e
        parceiros de entregas. Elas podem incluir informações como histórico de
        entregas ou antecedentes criminais (onde permitido por lei) e direito de
        trabalhar. A coleta disso pode ser feita por um prestador de serviços
        autorizado em nome da Abbiamo Log. Coletamos também a confirmação de
        identidade dos usuários Abbiamo Log para entrega de bebidas alcoólicas.
        Dados demográficos: podemos coletar dados demográficos sobre os
        usuários, inclusive por meio de pesquisas com usuários ou terceiros.
        Conteúdo do usuário: coletamos informações que os usuários enviam quando
        entram em contato com o suporte ao cliente da Abbiamo Log, fazem
        avaliações ou elogios para outros usuários ou estabelecimentos parceiros
        ou entram em contato com a Abbiamo Log de outra forma. Isso pode incluir
        comentários, fotos ou outros registros coletados por usuários.
        <br />
        <b>2.</b> Dados criados durante o uso dos nossos serviços. Isso inclui:
        Dados de localização: quando ativado pelo usuário, o recurso nos permite
        coletar dados de localização precisos ou aproximados do aparelho. Dos
        entregadores e parceiro de entregas, a Abbiamo Log coleta esses dados
        quando o app da Abbiamo Log está sendo executado em primeiro plano (app
        aberto e na tela) ou em segundo plano (app aberto, mas que não está na
        tela) no aparelho deles. Dos remetentes e destinatários de entrega, a
        Abbiamo Log coleta esses dados quando o app da Abbiamo Log está sendo
        executado em primeiro plano. Em certas regiões, a Abbiamo Log também
        pode coletar esses dados quando o app da Abbiamo Log está em execução em
        segundo plano no aparelho do usuário. Usuários, remetentes e
        destinatários de entrega podem usar o app da Abbiamo Log sem permitir
        que a Abbiamo Log colete dados de localização dos seus aparelhos. No
        entanto, isso pode afetar alguns recursos disponíveis no app da Abbiamo
        Log. Por exemplo, o usuário que não ativar a coleta de dados de
        localização precisará inserir manualmente seu endereço. Além disso, os
        dados de localização coletados do aparelho de um entregador ou parceiro
        de entrega durante o trajeto de entrega serão conectados com a conta do
        usuário, remetente e/ou destinatário, mesmo se ele não tiver ativado a
        coleta de dados de localização do seu aparelho, inclusive para fins de
        geração de recibos, suporte ao cliente, desvios, detecção de fraudes,
        seguro e disputas judiciais. Informações de transações: coletamos
        informações de transações relacionadas ao uso de nossos serviços,
        inclusive o tipo de serviço solicitado ou prestado, informações de
        entrega, a data e hora em que o serviço foi prestado, o valor cobrado, a
        distância percorrida e a forma de pagamento. Além disso, se alguém usar
        seu código promocional, podemos associar seu nome a essa pessoa. Dados
        de utilização: coletamos dados sobre a interação dos usuários com nossos
        serviços. Isso inclui datas e horários de acesso, recursos do app ou
        páginas visualizadas, problemas do app e outras atividades do sistema,
        tipo de navegador e sites ou serviços de terceiros utilizados antes da
        interação com nossos serviços. Em alguns casos, podemos fazer essa
        coleta por meio de cookies, pixels, tags e tecnologias similares que
        criam e mantêm identificadores únicos. Dados do aparelho: podemos
        coletar informações sobre os aparelhos usados para acessar nossos
        serviços, inclusive modelos de hardware, endereço IP do aparelho,
        sistemas operacionais e versões, software, idiomas preferidos,
        identificadores exclusivos de aparelhos, identificadores de publicidade,
        números de série, dados de movimento do aparelho e dados da rede móvel.
        <br />
        <b>3.</b> Dados de outras fontes. Isso inclui: Feedback do usuário, como
        avaliações, comentários ou elogios. Usuários que participam dos nossos
        programas de indicação. Por exemplo, quando um usuário indica alguém,
        nós recebemos os dados pessoais da pessoa indicada. Proprietários de
        conta da Abbiamo Log que solicitam serviços para outros usuários, ou em
        nome deles, ou que permitem que usuários solicitem ou recebam serviços
        por meio de suas contas. Isso inclui proprietários de contas da Abbiamo
        Log para Empresas. Usuários ou outras pessoas que fornecem dados
        relativos a reclamações ou contestações. Os parceiros comerciais da
        Abbiamo Log, por meio dos quais os usuários criam ou acessam sua conta
        da Abbiamo Log, como provedores de pagamento, serviços de redes sociais,
        ou apps ou sites que usam as APIs da Abbiamo Log ou cujas APIs a Abbiamo
        Log usa. Fornecedores que nos ajudam a confirmar a identidade dos
        usuários, informações de antecedentes e qualificação para a parceria,
        para fins de regulamentação e segurança. Provedores de seguro, veículo
        ou serviços financeiros para entregadores, motos, bicicletas e/ou
        parceiros de entregas. Empresas de transporte parceiras (para
        entregadores e parceiros de entregas que usam nossos serviços por meio
        de uma conta associada a tal empresa). Fontes publicamente disponíveis.
        Prestadores de serviços de marketing. A Abbiamo Log pode combinar os
        dados coletados dessas fontes com outros em sua posse.
        <br />
        <b>4.</b> Compartilhamento e divulgação de dados. Alguns dos produtos,
        serviços e recursos da Abbiamo Log exigem o compartilhamento de dados
        com outros usuários ou a pedido do usuário. Podemos também compartilhar
        dados com nossas afiliadas, subsidiárias e parceiras, por motivos
        jurídicos ou em conexão com reclamações ou conflitos. A Abbiamo Log pode
        compartilhar os dados que coleta:
        <br />
        <Wrap>
          <b>1.</b> Com outros usuários. Isso inclui compartilhar: O nome,
          avaliação e os locais de entrega com os entregadores parceiros. O nome
          dos usuários com outros usuários em uma entrega compartilhada. Os
          usuários em entregas compartilhadas também podem ver os endereços de
          entrega dos outros usuários. Nome, endereço de entrega e informações
          de pedido dos destinatários de entregas com o parceiro de entregas e o
          estabelecimento ou o restaurante. Podemos também compartilhar
          avaliações e comentários, ou outras informações no limite imposto pela
          lei, com o estabelecimento ou restaurante parceiro e o parceiro de
          entregas. Dos parceiros de entregas, podemos compartilhar os dados a
          seguir com os usuários, destinatários de entregas e estabelecimentos
          ou restaurantes parceiros: nome e foto; dados do veículo; localização
          (antes e durante a entrega); avaliação média por usuários; número
          total de entregas; tempo de uso do app da Abbiamo Log; informações de
          contato (dependendo das leis aplicáveis); e perfil do parceiro de
          entregas, inclusive elogios e outros comentários enviados por outros
          usuários. Também oferecemos recibos aos usuários, contendo informações
          como detalhamento dos valores cobrados, nome do parceiro de entregas,
          foto, mapa do trajeto e outras informações dessa natureza.
          <br />
          <b>2.</b> Mediante solicitação do usuário. Isso inclui
          compartilhamento de dados com: Outras pessoas mediante solicitação do
          usuário. Por exemplo, compartilhamos a previsão de chegada e
          localização da entrega com terceiros quando solicitado por esse
          usuário ou as informações de trajeto da entrega quando ele divide o
          valor com outro usuário. Parceiros comerciais da Abbiamo Log. Por
          exemplo, se um usuário solicitou uma entrega serviço por meio de uma
          parceria ou oferta promocional feita por um estabelecimento ou
          restaurante parceiro, a Abbiamo Log pode compartilhar certos dados com
          esse estabelecimento ou restaurante. Isso pode incluir, por exemplo,
          outros serviços, plataformas, apps ou sites que se integram com nossas
          APIs; prestadores de serviços; aqueles com uma API ou serviço com o
          qual nos integramos; ou estabelecimentos ou restaurantes parceiros ou
          outros parceiros comerciais da Abbiamo Log e seus usuários com conexão
          com promoções, concursos ou serviços especializados.
          <br />
          <b>3.</b> Com o público em geral. Perguntas ou comentários enviados
          por usuários em fóruns públicos, como blogs e páginas de redes sociais
          da Abbiamo Log, estão disponíveis ao público, inclusive dados pessoais
          que aparecem nessas perguntas ou comentários.
          <br />
          <b>4.</b> Com o proprietário da conta da Abbiamo Log. Se um usuário
          solicitar entrega ou fizer um pedido com uma conta de propriedade de
          outra pessoa, poderemos compartilhar as informações desse pedido,
          inclusive dados de localização em tempo real, com o proprietário da
          conta. Por exemplo, isso ocorre quando: Um usuário usa o perfil da
          Abbiamo Log para Empresas do seu empregador Um parceiro de entregas
          usa uma conta de propriedade de uma empresa de transporte parceira,
          estabelecimento ou restaurante parceiro ou associada a ela Um usuário
          solicita uma entrega organizada em um Perfil Familiar.
          <br />
          <b>5.</b> Com prestadores de serviço e parceiros comerciais da Abbiamo
          Log. A Abbiamo Log fornece dados a fornecedores, consultores,
          parceiros de marketing, empresas de pesquisa e outros prestadores de
          serviços ou parceiros comerciais. Eles incluem: Processadores e
          facilitadores de pagamentos Provedores de checagem de antecedentes e
          confirmação de identidade (apenas parceiros de entregas) Provedores de
          armazenamento em nuvem Parceiros e provedores de plataforma de
          marketing, inclusive serviços de publicidade em redes sociais
          Provedores de análise de dados Parceiros de pesquisa, inclusive os
          responsáveis por pesquisas ou projetos em parceria com a Abbiamo Log
          ou em nome dela Fornecedores que ajudam a Abbiamo Log a melhorar a
          segurança dos seus apps Consultores, advogados, contadores e outros
          prestadores de serviços profissionais Parceiros de seguros e
          financiamentos Aeroportos Estabelecimentos e restaurantes parceiros
          e/ou seus provedores de PDV.
          <br />
          <b>6.</b> Por motivos legais ou no caso de contestações. A Abbiamo Log
          pode compartilhar dados pessoais de usuários se acreditar que é
          obrigatório pela lei aplicável, regulamentos, licença de operação ou
          contrato, processo jurídico ou solicitação governamental, ou nos casos
          em que a divulgação seja apropriada, por outros motivos, devido a
          preocupações com a segurança ou similares. Isso inclui o
          compartilhamento de dados pessoais com agentes de autoridades
          policiais/jurídicas, agentes de saúde pública e outras autoridades
          governamentais, aeroportos (se exigido pelas autoridades
          aeroportuárias como condição para operar nas instalações do
          aeroporto), ou outros terceiros conforme o necessário para aplicar
          nossos Termos e Condições, contratos de usuário ou outras políticas;
          para proteger os direitos ou a propriedade da Abbiamo Log ou os
          direitos, a segurança ou as propriedades de outros; ou em caso de
          reclamação ou conflito com relação ao uso dos nossos serviços. Se você
          usar o cartão de crédito de outra pessoa, podemos ser obrigados por
          lei a compartilhar com o proprietário seus dados pessoais, incluindo
          informações de transporte ou pedido. Isso inclui também o
          compartilhamento de dados pessoais com terceiros associados, ou
          durante negociações de qualquer incorporação, venda de ativos da
          empresa, consolidação ou reestruturação, financiamento ou aquisição de
          todo ou parte de nossos negócios por outra empresa.
          <br />
          <b>7.</b> Com consentimento. A Abbiamo Log pode compartilhar os dados
          pessoais de um usuário de outras maneiras diferentes das descritas
          neste aviso, se notificarmos o usuário e ele consentir com o
          compartilhamento.
          <br />
          <b>8.</b> Segurança. Usamos dados pessoais para ajudar a manter a
          segurança e integridade de nossos serviços e usuários. Isso inclui:
          Processo de cadastro de usuários, entregadores e parceiros de
          entregas, antes de permitir o uso de nossos serviços e em intervalos
          subsequentes, inclusive análises de checagem de antecedentes, quando a
          lei permitir, para ajudar a impedir que usuários e entregadores que
          representem risco à segurança usem nossos serviços. Uso de dados dos
          aparelhos de entregadores ou parceiros de entregas para ajudar a
          identificar comportamento perigoso nas entregas e violações das leis
          de trânsito. Em certas regiões, o uso de informações derivadas de
          fotos da carteira de habilitação e outras fotos enviadas à Abbiamo
          Log, para fins de segurança. Isso inclui o recurso de confirmação de
          identidade em tempo real, que solicita que os entregadores e parceiros
          de entrega compartilhem uma selfie antes de ficar online para ajudar a
          garantir que a pessoa que usa o app seja a mesma da conta da Abbiamo
          Log que temos em arquivo. Isso também inclui comparar as fotos que
          temos em arquivo com as fotos (i) de outros usuários para impedir
          empréstimo de identidade e (ii) de bancos de dados públicos para
          confirmar a identidade do usuário.] Uso de dados do aparelho,
          localização, perfil, utilização e outros para impedir, detectar e
          combater fraudes ou atividades que representem risco à segurança. [Uso
          de avaliações e comentários do usuário para incentivar a conformidade
          com nosso Código de Conduta e como justificativa para desativação de
          parceiros de entregas com avaliações baixas ou que violaram tal código
          em certos países.]
          <br />
          <b>9.</b> Suporte ao cliente. A Abbiamo Log usa as informações que
          coleta (inclusive gravações de ligações para o suporte ao cliente, com
          aviso e consentimento do usuário) para oferecer suporte ao cliente,
          inclusive para: Encaminhar perguntas ao profissional de suporte
          apropriado Investigar e buscar soluções para as preocupações do
          usuário, monitorar e melhorar as respostas e os processos do nosso
          suporte ao cliente.
          <br />
          <b>10.</b> Pesquisa e desenvolvimento. Podemos usar os dados que
          coletamos para teste, pesquisa, análise, desenvolvimento de produtos e
          aprendizado de máquina para melhorar a experiência do usuário. Isso
          nos permite melhorar e aprimorar a segurança de nossos serviços,
          aumentar nossa capacidade de impedir o uso dos nossos serviços para
          fins ilícitos ou inadequados e desenvolver novos recursos e produtos.
          <br />
          <b>11.</b> Permitir comunicações entre usuários. Por exemplo, um
          entregador pode enviar uma mensagem ou ligar para um usuário para
          confirmar o local de partida, ou o usuário pode entrar em contato com
          o entregador para dar alguma orientação adicional, avisar sobre
          mudança de rota, tirar dúvidas, ou um estabelecimento, restaurante ou
          parceiro de entregas pode ligar para um usuário com informações sobre
          um pedido.
          <br />
          <b>12.</b> Marketing. A Abbiamo Log pode usar os dados que coleta para
          divulgar seus serviços a seus usuários. Isso inclui enviar
          comunicações aos usuários sobre serviços, recursos, promoções,
          sorteios, estudos, pesquisas, notícias, notificações e eventos da
          Abbiamo Log. A Abbiamo Log também pode enviar comunicações a seus
          usuários sobre produtos e serviços oferecidos por parceiros da Abbiamo
          Log. Por exemplo, quando um usuário fizer um pedido de entrega,
          podemos oferecer recomendações, promoções ou anúncios sobre
          estabelecimentos parceiros da Abbiamo Log que estejam no trajeto e que
          possam interessar o usuário. Embora possamos enviar comunicações aos
          usuários sobre produtos e serviços de parceiros da Abbiamo Log, não
          vendemos nem compartilhamos dados pessoais dos usuários com tais
          parceiros ou outros para fins de marketing direto ou publicidade,
          exceto com o consentimento dos usuários. Podemos usar os dados que
          coletamos para personalizar as comunicações de marketing (incluindo
          anúncios) que enviamos, inclusive com base na localização do usuário,
          no uso anterior dos serviços da Abbiamo Log e nas preferências e
          configurações do usuário. Podemos também enviar comunicações aos
          usuários com relação a eleições, votações, referendos e outros
          processos políticos relacionados a nossos serviços. Por exemplo,
          podemos avisar nossos usuários por e-mail sobre novas regulamentações
          de serviços de entrega ou protocolos para combate a pandemias, entre
          outros.
          <br />
          <b>13.</b> Comunicações sem fins de marketing. A Abbiamo Log pode usar
          os dados que coleta para gerar e enviar recibos aos usuários,
          informá-los sobre mudanças em nossos termos, serviços ou políticas ou
          enviar outras comunicações sem a finalidade de divulgar os serviços ou
          produtos da Abbiamo Log ou de seus parceiros.
          <br />
          <b>14.</b> Procedimentos e exigências legais. Podemos usar os dados
          pessoais que coletamos para investigar ou tratar de reclamações ou
          contestações relacionadas ao uso dos serviços da Abbiamo Log, ou de
          outra forma permitida pela lei aplicável, ou conforme solicitação de
          reguladores, entidades governamentais e consultas oficiais.
          <br />
          <b>15.</b> Decisões automatizadas. Usamos dados pessoais para tomar
          decisões automatizadas com relação ao uso dos nossos serviços. Isso
          inclui: [Ativar o preço dinâmico, no qual o preço de entrega dos
          pedidos é determinado segundo fatores que estão sempre mudando, como o
          tempo estimado e a distância, o trajeto previsto, o tráfego estimado e
          a quantidade de entregas e entregadores disponíveis em um dado
          momento.] Localizar entregadores e parceiros de entregas disponíveis
          para os usuários que solicitam serviços. Os usuários encontram o
          entregador com base na disponibilidade, na proximidade e em outros
          fatores. Determinar avaliações de entregadores e parceiros de entregas
          e desativar entregadores e usuários com avaliação baixa. Para mais
          informações, consulte também a seção {"Visualização de avaliações"}
          abaixo. Desativar usuários que foram identificados como envolvidos em
          fraudes ou atividades que possam prejudicar a Abbiamo Log, seus
          usuários ou outras pessoas. Usar informações de localização de
          entregadores e comunicação entre usuários e entregadores para
          identificar taxas de cancelamento recebidas ou induzidas por meio de
          fraudes. Para contestar uma desativação resultante desses processos,
          entre em contato com o suporte ao cliente da Abbiamo Log.
          <br />
          <b>16.</b> Fornecer serviços e recursos. Usamos os dados que coletamos
          para oferecer, personalizar, manter e melhorar nossos produtos e
          serviços. Isso inclui usar os dados para: Criar e alterar contas de
          usuários. Confirmar identidade, antecedentes e qualificação de
          entregadores e parceiros de entregas. Viabilizar transporte, entregas
          e outros serviços. Oferecer, processar ou facilitar pagamentos dos
          nossos serviços. Oferecer, obter, fornecer ou facilitar soluções de
          seguro, faturamento ou financeiras relacionadas aos nossos serviços.
          Acompanhar e compartilhar o progresso das entregas. Oferecer recursos
          que possibilitem que os usuários compartilhem informações com outras
          pessoas, como elogios de usuários a entregadores, comentários de
          destinatários de entregas a restaurantes, estabelecimentos ou
          entregadores, indicação de amigos à Abbiamo Log, divisão de valor ou
          compartilhamento de previsão de chegada e localização com contatos.
          Oferecer recursos para personalizar as contas dos usuários da Abbiamo
          Log, como criação de locais favoritos, e para permitir acesso rápido a
          destinos anteriores. Podemos, por exemplo, apresentar recomendações
          personalizadas de restaurantes ou pratos a usuários Abbiamo Log com
          base em pedidos anteriores. Consulte a seção denominada
          {"Opções e" + "transparência"} para saber como se opor ao uso dos
          dados pessoais. Oferecer recursos de acessibilidade que facilitem o
          uso de nossos serviços para usuários com deficiências, tais como os
          que permitem que entregadores com deficiência auditiva ou surdez
          alertem os usuários sobre sua condição, que só permitem o envio de
          mensagens de texto pelos usuários e que habilitam o recebimento de
          avisos luminosos de solicitação de entrega em seu aparelho, em vez de
          avisos sonoros. Realizar operações internas necessárias para prestar
          nossos serviços, inclusive para solucionar problemas de software e
          operacionais, conduzir análises de dados, testes e pesquisas e
          monitorar e analisar tendências de uso e atividade.
        </Wrap>
      </Paragraph>
      <br />
      <ParagraphTitle>IV. DIREITOS SOBRE OS DADOS PESSOAIS</ParagraphTitle>
      <Paragraph>
        Você, enquanto titular desses dados coletados, possui os seguintes
        direitos:
        <br />
        <Wrap>
          • Acessar, revisar, saber se realizamos algum tratamento com seus
          dados pessoais e requisitar uma cópia eletrônica da informação que
          temos sobre você;
          <br />
          • Corrigir ou solicitar a correção de dados pessoais incompletos,
          inexatos ou desatualizados;
          <br />
          • Solicitar a anonimização, bloqueio ou eliminação de dados pessoais
          desnecessários e excessivos;
          <br />
          • Solicitar a portabilidade dos dados pessoais a outro fornecedor de
          produtos ou serviços similares;
          <br />
          • Solicitar a eliminação dos dados pessoais coletados e utilizados com
          base no seu consentimento;
          <br />
          • Você tem o direito de fazer perguntas ou reclamações junto à
          autoridade de proteção de dados local a qualquer momento.
          <br />
        </Wrap>
        É bom deixarmos claro que que os seus direitos e opções variam de acordo
        com a sua localização e seu tipo de vínculo conosco.
        <br />
        Determinadas informações pessoais poderão estar isentas destas
        solicitações nos termos da legislação aplicável. Precisamos de
        determinados tipos de informações pessoais para fornecermos o produto e
        os serviços a você. Se você solicitar a exclusão dessas informações,
        talvez não possa acessar ou utilizar o nosso produto e serviço
        posteriormente.
      </Paragraph>
      <br />
      <ParagraphTitle>V. RETENÇÃO E EXCLUSÃO DOS DADOS </ParagraphTitle>
      <Paragraph>
        <b>A.</b> A Abbiamo Log retém dados de perfil do usuário, transações e
        outros dados pessoais enquanto o usuário mantiver sua conta da Abbiamo
        Log. A Abbiamo Log pode reter alguns dados do usuário após receber a
        solicitação de exclusão da conta para cumprir requisitos legais. A
        Abbiamo Log reterá o perfil do usuário, transações e outras informações
        pessoais enquanto o usuário mantiver sua conta da Abbiamo Log. A Abbiamo
        Log também armazenará informações do veículo e da verificação de
        antecedentes (dentro dos limites permitidos por lei) dos parceiros de
        entregas, enquanto eles mantiverem a conta da Abbiamo Log. Usuários e
        destinatários de entregas podem solicitar a exclusão da sua conta a
        qualquer momento pelos menus Configurações e Privacidade no app da
        Abbiamo Log ou pela Central de Ajuda da Abbiamo Log. Depois de tal
        solicitação, a Abbiamo Log excluirá os dados que não é obrigada a
        armazenar para fins de regulamentos, impostos, seguro, disputas
        judiciais ou outras exigências legais. Por exemplo, a Abbiamo Log reterá
        dados de localização, dispositivo e utilização para esses fins por pelo
        menos 5 anos. Durante esse período, ela também poderá utilizá-los para
        fins de segurança, prevenção e detecção de fraudes, pesquisa e
        desenvolvimento. Em algumas circunstâncias, pode ser que a Abbiamo Log
        não consiga excluir uma conta de usuário, por exemplo, se houver valores
        pendentes na conta ou alguma reclamação ou conflito ainda sem resolução.
        Depois que esse problema for resolvido, a Abbiamo Log excluirá a conta
        da forma descrita acima. A Abbiamo Log pode também reter certas
        informações se for necessário para fins de segurança e prevenção de
        fraudes. Por exemplo, se a Abbiamo Log desativar a conta de um usuário
        por causa de um comportamento inseguro ou incidentes ligados à
        segurança, poderá reter certas informações sobre essa conta para evitar
        que tal usuário abra uma nova conta Abbiamo Log no futuro.
        <br />
        <b>B.</b> A Abbiamo Log coleta e usa dados pessoais apenas quando há
        bases legais para tanto. Isso inclui processamento de dados pessoais:
        (i) para prestar os serviços e oferecer os recursos solicitados; (ii)
        com base em consentimento do titular; (iii) para o cumprimento de
        obrigação legal ou regulatória; (iv) para o exercício regular de
        direitos em processo judicial, administrativo ou arbitral; (v) para a
        proteção da vida ou da incolumidade física do usuário ou de terceiro;
        (vi) para atender aos interesses legítimos da Abbiamo Log ou de
        terceiro; (vii) para segurança e prevenção à fraude e (viii) para a
        proteção do crédito. Coletamos e usamos dados pessoais apenas quando
        temos uma ou mais bases legais para isso. Estas bases dependem da
        localização dos usuários, mas geralmente incluem processamento de dados
        pessoais:
        <br />
        <Wrap>
          <b>1.</b> Para prestar os serviços e oferecer os recursos solicitados.
          Para prestar nossos serviços, precisamos coletar e usar certos dados
          pessoais. Isso inclui: Dados do perfil de usuário, usados para
          estabelecer e manter contas de usuários; confirmar a identidade do
          usuário; nos comunicar com usuários sobre suas viagens, pedidos e
          contas; e permitir que os usuários façam pagamentos ou recebam ganhos
          Informações de antecedentes, usadas para confirmar a qualificação para
          ser um parceiro de entregas Dados de localização do parceiro de
          entregas, usados para acompanhar trajetos e ajudar com a navegação
          Dados de utilização, necessários para manter, otimizar e melhorar os
          serviços da Abbiamo Log, inclusive para determinar promoções, conectar
          entregadores e usuários e calcular custos de entregas e ganhos dos
          entregadores parceiros Informações de transações Informações
          relacionadas com o suporte ao cliente.
          <br />
          <b>2.</b> Para fins dos interesses legítimos da Abbiamo Log e de
          outras partes. Isso inclui usar dados pessoais para manter e melhorar
          a segurança de nossos usuários. Por exemplo, usamos dados pessoais
          para evitar o uso de nossos serviços por usuários que tenham
          apresentado comportamentos inapropriados ou perigosos, como ao guardar
          dados de usuários banidos para evitar que eles usem os apps da Abbiamo
          Log. Isso também inclui fins como combate a fraudes; melhoria dos
          nossos serviços, marketing direto, pesquisa e desenvolvimento; e
          aplicação dos Termos e Condições da Abbiamo Log. Além disso, isso
          inclui usar dados pessoais no limite necessário aos interesses de
          outras pessoas ou do público em geral, como quando relacionados às
          ações judiciais ou comunicações de sinistro e para proteção de
          direitos e segurança de outros.
          <br />
          <b>3.</b> Para cumprir as obrigações jurídicas da Abbiamo Log. Por
          exemplo, a Abbiamo Log está sujeita a leis e regulamentações que
          exigem que ela colete e armazene dados sobre as entregas de nossos
          usuários e forneça cópias de tais dados ao governo e outras
          autoridades. Coletamos e usamos dados pessoais para cumprir tais leis.
          A Abbiamo Log também pode compartilhar dados com autoridades
          policiais/jurídicas com relação a atos criminosos ou ameaças à
          segurança pública ou solicitações de terceiros em conformidade com
          processos judiciais.
          <br />
          <b>4.</b> Com consentimento. A Abbiamo Log pode coletar e usar dados
          pessoais com base no consentimento do usuário. Por exemplo, coletamos
          dados pessoais por meio de pesquisas voluntárias. As respostas a essas
          pesquisas são coletadas com seu consentimento e serão excluídas assim
          que não forem mais necessárias ao propósito para o qual foram
          coletadas. Um usuário que consentiu com a coleta e uso de seus dados
          pessoais pode revogar o consentimento quando quiser. No entanto, o
          usuário não poderá usar nenhum serviço ou recurso que exija a coleta
          ou uso desses dados pessoais.
          <br />
        </Wrap>
      </Paragraph>
      <br />
      <ParagraphTitle>VI. OPÇÕES E TRANSPARÊNCIA </ParagraphTitle>
      <Paragraph>
        A Abbiamo Log permite que os usuários acessem e controlem os dados que a
        Abbiamo Log coleta, inclusive por meio de: Configurações no app;
        Permissões do aparelho; Páginas de avaliações no app; Não recebimento de
        comunicações de marketing. A Abbiamo Log também permite que os usuários
        solicitem acesso ao seus dados ou cópias dos mesmos, alterações ou
        atualizações em suas contas, exclusão de suas contas ou que a Abbiamo
        Log restrinja seu processamento de dados pessoais do usuário. A.
        Configurações de privacidade: Os menus de configuração do app da Abbiamo
        Log para usuários oferecem a eles a capacidade de configurar ou alterar
        as preferências de compartilhamento de localização e as preferências
        para receber notificações da Abbiamo Log no aparelho. Informações sobre
        essas configurações, procedimento para definir ou alterar essas
        configurações e o efeito de desativá-las são descritos abaixo.
        <br />
        <Wrap>
          <b>1.</b> Dados de localização: A Abbiamo Log usa os serviços de
          localização do aparelho do usuário para que seja mais fácil solicitar
          uma entrega quando ele precisar. Os dados de localização nos ajudam a
          aprimorar serviços, como locais de entregas, trajetos e suporte ao
          cliente. Pelo menu Configurações {">"} Privacidade no app da Abbiamo
          Log, os usuários podem ativar ou desativar a coleta de dados de
          localização dos aparelhos por parte da Abbiamo Log. Os usuários,
          remetentes e destinatários de entregas podem usar as configurações em
          seus aparelhos para permitir ou cancelar a permissão de tais coletas.
          <br />
          <b>2.</b> Avisos de atualizações de conta e entrega: A Abbiamo Log
          envia aos usuários avisos sobre o status da entrega e atualizações
          relacionadas à atividade em sua conta. Essas notificações são
          necessárias para o uso do app da Abbiamo Log e não podem ser
          desativadas. No entanto, os usuários podem escolher o método de
          recebimento desses avisos no menu Configurações {">"} Privacidade, no
          app da Abbiamo Log.
          <br />
          <b>3.</b> Avisos de descontos e notícias: Os usuários podem permitir
          que a Abbiamo Log envie notificações push sobre descontos e notícias
          da Abbiamo Log. As notificações push podem ser ativadas ou desativadas
          no menu Configurações {">"} Privacidade no app da Abbiamo Log.
          <br />
          <b>4.</b> Comunicações de restaurantes parceiros: Os destinatários de
          entregas que optaram por receber comunicações dos estabelecimentos e
          dos restaurantes parceiros podem parar de compartilhar seus dados com
          cada um deles nas configurações da conta da Abbiamo Log.
          <br />
          <b>5.</b> Permissões do aparelho: A maioria das plataformas de
          aparelhos móveis (iOS, Android, etc.) definiu que certos tipos de
          dados do aparelho não podem ser acessados por apps sem a permissão do
          proprietário. Essas plataformas usam diferentes formas de obtenção
          dessas permissões. Os aparelhos iOS notificam os usuários na primeira
          vez que o app da Abbiamo Log solicita permissão para acessar certos
          tipos de dados e oferecem aos usuários a opção de conceder ou
          recusá-la. Os aparelhos Android notificam os usuários sobre as
          permissões que o app da Abbiamo Log pede antes de você usá-lo pela
          primeira vez. Ao usar o app, você concede tal permissão.
          <br />
          <b>6.</b> Visualização de avaliações: depois de cada entrega, os
          usuários e entregadores podem avaliar uns aos outros em uma escala de
          1 a 5. A média dessas avaliações é associada à conta do usuário e
          exibida aos outros usuários para os quais eles oferecem ou dos quais
          recebem serviços. Por exemplo, os entregadores podem ver a avaliação
          dos usuários dos quais recebem solicitações de entrega, e os usuários
          podem ver a avaliação desses entregadores. [Esse sistema de avaliação
          recíproca faz com que cada pessoa seja responsável por seu
          comportamento. A responsabilidade ajuda a criar um ambiente eficiente,
          respeitoso e seguro para usuários e entregadores. Os usuários podem
          ver a média de avaliações deles no menu principal do app da Abbiamo
          Log. Os entregadores podem ver a média de avaliações deles ao tocar na
          foto de perfil no app de parceiro.] Os parceiros de entregas também
          são avaliados pelos estabelecimentos e restaurantes parceiros.
          <br />
          <b>7.</b> Não recebimento de comunicações de marketing: Os usuários
          podem optar por não receber e-mails promocionais da Abbiamo Log. Os
          usuários também podem seguir as instruções de cancelamento de
          assinatura contidas nessas mensagens para optar por não receber
          e-mails e outras mensagens da Abbiamo Log. Podemos ainda enviar outros
          tipos de mensagens, como recibos de entregas ou informações da conta,
          aos usuários que optaram por cancelar as comunicações promocionais.
          <br />
          <b>8.</b> Solicitações de dados do usuário: A Abbiamo Log oferece
          várias maneiras para os usuários conhecerem e controlarem seus dados e
          enviarem dúvidas e comentários sobre o tratamento deles pela Abbiamo
          Log. Para fazer uma solicitação, envie um e-mail com a requisição
          necessária para lgpd@abbiamolog.com Acesso aos dados: os usuários
          podem pedir uma explicação sobre os dados que coletamos e como eles
          são usados. Recebimento de dados: os usuários podem solicitar uma
          cópia dos dados que a Abbiamo Log coleta deles com consentimento ou
          conforme necessário para prestar nossos serviços. Alteração ou
          atualização de dados: os usuários podem editar nome, número de
          telefone, endereço de e-mail, forma de pagamento e foto associados à
          sua conta ao acessar o menu {"Configurações"} no app da Abbiamo Log ou
          pelo portal do entregador parceiro. Eles também podem pedir para que a
          Abbiamo Log altere ou atualize seus dados, inclusive nos casos em que
          acreditarem que eles estejam errados ou incompletos. Exclusão de
          dados: os usuários podem solicitar a exclusão da sua conta quando
          quiserem, pelo menu Configurações {">"} Privacidade no app da Abbiamo
          Log, ou pelo site da Abbiamo Log. Objeções, restrições e reclamações:
          os usuários podem solicitar a interrupção ou a limitação do nosso uso
          de todos os seus dados pessoais ou de parte deles. A Abbiamo Log pode
          continuar a processar dados após tal objeção ou solicitação, no limite
          exigido ou permitido por lei. Além disso, dependendo da localização,
          os usuários podem ter o direito de registrar uma reclamação
          relacionada ao tratamento dos seus dados pessoais junto à autoridade
          de proteção de dados do seu país.
          <br />
        </Wrap>
      </Paragraph>
      <br />
      <ParagraphTitle>VII. ALTERAÇÕES </ParagraphTitle>
      <Paragraph>
        Ocasionalmente, podemos alterar este aviso. Se fizermos alterações
        significativas, notificaremos com antecedência os usuários sobre elas
        pelo app da Abbiamo Log ou outros meios, como e-mail. Recomendamos que
        os usuários revejam periodicamente este aviso para saber as informações
        mais recentes sobre nossas práticas de privacidade. Depois desse aviso,
        o uso dos nossos serviços pelos usuários em países fora da União
        Europeia será entendido como um consentimento com as alterações, até o
        limite permitido por lei.
      </Paragraph>
      <br />
      <ParagraphTitle>VIII. COOKIES </ParagraphTitle>
      <Paragraph>
        Sua privacidade: Ao visitar um site, com a ajuda dos cookies, arquivos
        de internet que armazenam temporariamente seus acessos, armazenamos seu
        comportamento dentro daquele ambiente. A ideia é que essas informações
        sejam utilizadas a seu favor, principalmente, para oferecer uma
        experiência mais personalizada, com produtos e serviços que façam a
        diferença para você. No entanto, em respeito ao seu direito de
        privacidade, é possível permitir ou não certos tipos de cookies, que
        estão disponíveis para você alterar em nossas configurações, a qualquer
        momento. Em muitos casos, você pode gerenciar as preferências de cookies
        e optar por não receber cookies e outras tecnologias de coleta de dados
        ajustando as configurações em seu navegador.
        <br />
        II. Cookies Essenciais: Como o título já diz, esses cookies são
        necessários para o funcionamento do site e não podem ser desativados.
        Geralmente, são definidos apenas em resposta a suas ações. Por exemplo:
        solicitação de serviços; definição de preferências de privacidade;
        acesso ao login e preenchimento de formulários. É possível configurar
        seu navegador para bloquear ou para alertá-lo sobre esses cookies, mas,
        ainda assim, em algumas partes do site isso não funcionará. Esses
        cookies não armazenam informações identificáveis.
        <br />
        III. Cookies de Desempenho: Com eles, há como contar suas visitas e as
        fontes de tráfego para mensuração e possíveis ajustes no desempenho do
        site. Dessa forma, podemos saber quais páginas são mais ou menos
        relevantes. As informações coletadas por esses cookies são anônimas. Sem
        habilitar esta opção, não conseguimos saber quando você visitou nosso
        site e, portanto, não conseguimos monitorar o desempenho.
        <br />
        IV. Cookies de Funcionalidade: Ao permitir esses cookies, você consegue
        armazenar suas preferências. Assim, você não precisa inserir seus dados
        mais de uma vez em um formulário, por exemplo.
        <br />
        V. Cookies de publicidade: podem ser definidos pelos nossos parceiros
        publicitários, por meio do nosso site. A partir disso, criam um perfil
        de seu interesse, mostrando anúncios relevantes em outros sites. Nesse
        caso, não armazenam informações pessoais diretamente, mas são baseados
        na identificação exclusiva de seu navegador e dispositivo da internet.
        Se você não permitir esses cookies, deixará de contar com uma
        publicidade mais assertiva.
        <br />
        VI. Cookies Flash: Podemos usar Cookies Flash, também conhecidos como
        Objetos Localmente Armazenados, e tecnologias semelhantes para
        personalizar e aprimorar sua experiência online. Um Cookie Flash é um
        pequeno arquivo de dados armazenado em um computador usando a tecnologia
        Adobe Flash. O Adobe Flash Player é um aplicativo que permite o rápido
        desenvolvimento de conteúdos dinâmicos, como videoclipes e animações.
        Usamos Cookies Flash para personalizar e aprimorar sua experiência
        online e para fornecer conteúdo para reprodutores Flash. Também podemos
        usar Cookies Flash para fins de segurança, para reunir certas métricas
        do site e para ajudar a lembrar configurações e preferências. Os Cookies
        Flash são gerenciados por meio de uma interface diferente daquela
        fornecida pelo seu navegador. Para gerenciar Cookies Flash, visite o
        site da Adobe. Observe que, se você desabilitar os Cookies Flash ou
        outras tecnologias semelhantes, você pode não ter acesso a determinados
        conteúdos e recursos do produto, como seu dispositivo lembrar de um
        Anúncio que você visualizou durante uma visita anterior.
        <br />
        VII. Logs de Servidor e Outras Tecnologias: Coletamos muitos tipos
        diferentes de informações de logs de servidor e outras tecnologias. Por
        exemplo, coletamos informações sobre o dispositivo que você usa para
        acessar a Abbiamo Log, seu tipo de sistema operacional, tipo de
        navegador, domínio e outras configurações do sistema, bem como o idioma
        que seu sistema usa e o país e fuso horário onde seu dispositivo está
        localizado. Nossos logs de servidor também registram o endereço IP do
        dispositivo que você usa para se conectar à Internet. Um endereço IP é
        um identificador exclusivo que os dispositivos precisam para se
        identificar e se comunicar na Internet. Também podemos coletar
        informações sobre o site que você visitou antes de entrar na Abbiamo Log
        e o site que você visitou depois de sair da página da Abbiamo Log. Essas
        ferramentas nos ajudam a melhorar a experiência do usuário e a prestar
        nossos serviços.
        <br />
        VIII. Informações do Dispositivo: Podemos usar informações relacionadas
        ao dispositivo para autenticar usuários. Por exemplo, podemos usar seu
        endereço IP, informações do navegador ou outros dados fornecidos por seu
        navegador ou dispositivo para identificar o dispositivo que está sendo
        usado para acessar a Abbiamo Log. Também podemos usar essas técnicas
        relacionadas ao dispositivo para associá-lo a diferentes dispositivos
        que você pode usar para acessar nosso conteúdo, inclusive para fins de
        proteção contra fraude e para melhor direcionar a publicidade.
        <br />
        IX. Terceiros: Terceiros, incluindo o Facebook, colocam tecnologias como
        pixels e SDKs na Abbiamo Log. Essas tecnologias (1) nos ajudam a
        analisar como você usa a Abbiamo Log, por exemplo, notando os serviços
        de terceiros pelos quais você chegou, (2) comercializam e anunciam
        serviços da Abbiamo Log para você no nosso site e em sites de terceiros,
        (3) nos ajudam a detectar ou prevenir fraudes e realizar avaliações de
        risco, e (4) coletam informações sobre suas atividades na Abbiamo Log,
        em outros sites e/ou nos anúncios em que você clicou. Por exemplo, para
        nos ajudar a entender melhor como as pessoas usam a Abbiamo Log,
        trabalhamos com vários parceiros de análise, incluindo o Google
        Analytics. Para evitar que o Google Analytics use suas informações para
        análises, você pode instalar o add-on do navegador para desativação do
        Google Analytics clicando aqui. Terceiros, incluindo o Facebook, também
        podem utilizar essas tecnologias de rastreamento para coletar ou receber
        informações da Abbiamo Log e de outros lugares e usá-las para veicular
        anúncios que eles acreditam ser do seu interesse e para medir a eficácia
        de seus anúncios, tanto na Abbiamo Log quanto em outros sites e serviços
        online. Os cookies de segmentação e de publicidade que usamos podem
        incluir o Google e outras redes e serviços de publicidade que usamos
        ocasionalmente. Consulte aqui informações sobre como o Google gerencia
        dados em seus produtos de publicidade. Para desativar a exibição de
        publicidade pelo Google Analytics ou para personalizar os anúncios da
        rede de display do Google, visite a página de Configurações de anúncios
        do Google. Enquanto a tecnologia de publicidade estiver integrada à
        Abbiamo Log, ainda que você opte por excluir a publicidade
        personalizada, poderá receber conteúdo publicitário. Nesse caso, o
        conteúdo publicitário não será ajustado aos seus interesses. Além disso,
        não controlamos nenhum desses links de desativação e não somos
        responsáveis pela disponibilidade ou precisão dos mesmos. Os usuários
        podem desativar a coleta e o uso de informações para a segmentação de
        anúncios atualizando as configurações de anúncios da sua conta do
        Facebook e entrando em contato com a Abbiamo Log através do nosso e-
        mail, com uma descrição da sua solicitação e informações de validação.
        <br />
        X. Plugins Sociais de Terceiros: A Abbiamo Log pode usar plugins sociais
        fornecidos e operados por terceiros, como o Botão Curtir do Facebook.
        Como resultado disso, você pode enviar a terceiros as informações que
        você está visualizando em uma determinada parte da Abbiamo Log. Se você
        não estiver conectado à sua conta com o terceiro, ele poderá não saber
        sua identidade. Se você estiver conectado à sua conta com o terceiro,
        então ele poderá vincular informações ou ações sobre as interações que
        você tiver com a Abbiamo Log à sua conta com eles.
        <br />
      </Paragraph>
      <br />
      <ParagraphTitle>IX. COMO NOS CONTATAR</ParagraphTitle>
      <Paragraph>
        Para dúvidas ou reclamações sobre o Nosso uso das suas informações
        pessoais ou sobre a nossa Política de Privacidade, entre em contato
        conosco pelo e-mail <b>lgpd@abbiamolog.com</b>.
      </Paragraph>
    </Container>
  );
};

export default PolicyContent;

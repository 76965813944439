import React, { useState } from "react";
import Layout from "../components/layout";
import Sidebar from "../components/organisms/Sidebar/sidebar.components";
import Nav from "../components/organisms/Nav/nav.components";
import Footer from "../components/organisms/Footer/footer.component";
import SEO from "../components/seo";
import PolicyContent from "../components/organisms/Policy/policy.components";
const Policy: React.FC = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <Layout>
      <SEO title="Política de Privacidade" />
      <Sidebar open={open} toggle={toggle} />
      <Nav toggle={toggle} open={open} />
      <PolicyContent />
      <Footer />
    </Layout>
  );
};

export default Policy;

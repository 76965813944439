import styled, { css } from "styled-components";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { Link } from "gatsby";
export const Container = styled.div`
  background-color: #000000;
  width: 100%;
  bottom: 0;
`;
export const Wrapper = styled.div`
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  max-width: 78.75rem;
  margin: 0 auto;

  @media screen and (min-width: 500px){
    padding 50px;
  }
`;
export const Newsletter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;

  @media screen and (min-width: 768px) {
    padding: 40px 0px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
`;

export const Field = styled.div`
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 1.4rem;
  text-rendering: geometricPrecision;
  margin: 5px 0px 5px 0px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.8rem;
  }

  @media screen and (min-width: 800px) {
    text-align: left;
  }

  @media screen and (min-width: 1000px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.h5`
  color: #ffffff;
  text-rendering: geometricPrecision;
  margin-bottom: 20px;
  text-align: center;
  @media screen and (min-width: 800px) {
    text-align: left;
  }
`;

export const InputField = styled.div`
  display: grid;
  grid-template-areas: "input button";
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 3px;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  grid-area: input;
  grid-template-columns: 50px min-content 1fr;
  height: 45x;
  background-color: #292929;
  border: none;
  outline: none;
  font-size: 0.9rem;
  color: #ffffff;
  text-indent: 10px;
  min-width: 175px;
  border-radius: 2px;

  :placeholder {
    color: #818181;
  }
  @media screen and (min-width: 381px) {
    min-width: 320px;
    width: 100%;
  }
`;

export const Button = styled.button`
  grid-area: button;
  grid-column: 1 / col4-start / last-line / 6;
  background-color: #6ed796;
  min-width: 70px;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 2px;
  color: #00000;
  cursor: pointer;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #0f0f0f;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  bottom: 0;

  @media screen and (min-width: 550px) {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  color: #808080;
  font-size: 0.8rem;
  gap: 20px;
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const Text = styled.span`
  color: #808080;
  font-size: 0.8rem;
  align-content: center;
  text-align: center;

  @media screen and (min-width: 700px) {
    text-align: left;
  }
`;
export const IconStyle = css`
  color: #808080;
  font-size: 1.2rem;

  &:hover,
  &:focus {
    color: #6ed796;
  }
`;
export const FacebookIcon = styled(AiFillFacebook)`
  ${IconStyle};
`;
export const InstagramIcon = styled(AiOutlineInstagram)`
  ${IconStyle};
`;
export const LinkedinIcon = styled(FaLinkedinIn)`
  ${IconStyle};
`;
export const FooterLink = styled.a`
  text-decoration: none;
`;

export const LinkFooter = styled(Link)`
  text-decoration: none;
  color: #808080;
`;

export const SuccessMessage = styled.div`
  max-width: 500px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  align-content: center;

  @media screen and (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const SuccessTitle = styled.div`
  font-size: 21px;
  text-align: center;
`;

export const SuccessSubtitle = styled.div`
  font-size: 14px;
`;

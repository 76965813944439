import React, { useState } from "react";
import {
  Container,
  Newsletter,
  Field,
  Title,
  Subtitle,
  Input,
  Button,
  Wrapper,
  Divider,
  Row,
  Column,
  Text,
  InputField,
  SuccessMessage,
  SuccessTitle,
  SuccessSubtitle,
  FooterLink,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  LinkFooter,
} from "./footer.styles";

const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      fill="#6ed796"
      width="45"
      height="45">
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path d="M4561.4,5000.9c-1152.7-109.1-2192.4-599.3-3015.8-1424.6c-411.7-411.7-702.7-815.7-947.8-1321.2c-160.8-331.3-252.8-578.3-335.1-900C117.1,784.5,69.3,242.6,119-291.6c90-959.3,421.3-1807.6,1009.1-2581.1c191.5-250.8,630-689.3,880.8-880.8C2941.4-4464,4052-4822,5174.1-4774.1c1269.5,51.7,2387.8,541.9,3283.9,1438C9354.1-2440,9844.3-1321.8,9896-52.3c55.5,1342.3-465.3,2657.7-1438,3628.5c-836.8,836.8-1863.1,1317.4-3044.5,1426.5C5174.1,5023.9,4800.7,5023.9,4561.4,5000.9z M7198,1592.6c168.5-82.3,245.1-239.3,231.7-472.9c-13.4-237.4-5.7-227.9-1281-1570.1c-810-852.1-1183.3-1233.1-1235-1258c-166.6-78.5-394.4-47.9-536.1,74.7c-42.1,36.4-377.2,394.4-746.8,794.6C2876.3-15.9,2847.6,24.3,2847.6,242.6c1.9,101.5,11.5,155.1,42.1,214.5c47.9,90,157,193.4,247,229.8c141.7,59.4,354.2,17.2,467.2-90c26.8-24.9,279.6-298.7,562.9-607c283.4-306.4,522.7-559.1,528.5-559.1c7.7,0,436.6,442.3,951.7,982.3c517,538.1,974.6,1018.7,1018.7,1062.7C6817,1630.9,7023.8,1676.8,7198,1592.6z" />
        </g>
      </g>
    </svg>
  );
};
const Message = (props) => {
  return (
    <SuccessMessage>
      <SuccessIcon />
      <SuccessTitle>{props.title}</SuccessTitle>
      <SuccessSubtitle>{props.subtitle}</SuccessSubtitle>
    </SuccessMessage>
  );
};

const Footer: React.FC = () => {
  const [status, setStatus] = useState<string>("");
  const [show, setShow] = useState(false);
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShow(!show);
    setStatus("success");
  };
  return (
    <Container>
      <Wrapper>
        <Newsletter>
          <Field>
            <Title>Não perca nenhuma novidade!</Title>
            <Subtitle>
              Assine a nossa newsletter, enviaremos dicas e novidades
              semanalmente.
            </Subtitle>
          </Field>
          <Field>
            {!show && (
              <form name="newsletter" onSubmit={onSubmit}>
                <InputField>
                  <Input
                    type="email"
                    placeholder="Digite o seu e-mail*"
                    name="email"
                    required
                  />
                  <Button type="submit">Assinar</Button>
                </InputField>
              </form>
            )}
            {show && status === "success" ? (
              <Message
                title={"Obrigado por se inscrever!"}
                subtitle={
                  "Agora iremos enviar semanalmente notícias e dicas, preparadas pelo Time Abbiamo."
                }
              />
            ) : (
              ""
            )}
          </Field>
        </Newsletter>
        <Divider />
        <Row>
          <Column>
            <Text>
              ©{new Date().getFullYear()} ABBIAMO LOGISTICA LTDA. Todos os
              direitos reservados.
            </Text>
          </Column>
          <Column>
            <LinkFooter to="/politica-de-privacidade">
              Politica de privacidade
            </LinkFooter>
            <FooterLink
              href="https://www.facebook.com/abbiamolog"
              target="_blank">
              <FacebookIcon />
            </FooterLink>
            <FooterLink
              href="https://www.instagram.com/abbiamolog/"
              target="_blank">
              <InstagramIcon />
            </FooterLink>
            <FooterLink
              href="https://www.linkedin.com/company/abbiamo-log/"
              target="_blank">
              <LinkedinIcon />
            </FooterLink>
          </Column>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default Footer;
